import request from './ajax'
import qs from 'qs'
export const getUserCompanyAuthList = (data) => request({
    method: "get",
    url: "/auth/company/list",
    params: data,
})

export const getUserRealNameAuthList = (data) => request({
    method: "get",
    url: "/auth/realname/list",
    params: data,
})

export const getUserAllAuthList = (data) => request({
    method: "get",
    url: "/auth/list",
    params: data,
})

export const getAuthCateList = (data) => request({
    method: "get",
    url: "/service/category/list",
    params: data,
})

export const getConfigList = (data) => request({
    method: "get",
    url: "/common/config",
    params: data,
})


