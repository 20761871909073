<template>
  <div class="page-main">
    <div class="page-header">
      <div class="title">{{ pageTitle }}</div>
      <div class="handle">
        <el-button v-show="rechargeBox||transferBox" @click="hideRecharge" class="handle-button" type="text">
          返回概览
        </el-button>
      </div>
    </div>

    <div class="page-content">
      <div class="tip" v-show="indexBox&&companyAuth.status!=9">
        <Isvg icon-class="exclamation-mark"/>
        <span>需要完成企业认证，并使用对公账户转账充值。</span>
      </div>
      <div class="tip" v-show="rechargeBox">
        <Isvg icon-class="exclamation-mark"/>
        <span>充值即代表您同意授权使用您的账户来支付这笔押金和未来的付款。</span>
      </div>

      <div class="top-content-box" v-show="indexBox">
        <!-- 认证情况展示-->
        <div class="auth-info" v-show="companyAuth.status!=9">
          <div class="icon-div">
            <Isvg icon-class="recharge-auth" class-name="auth-icon"/>
          </div>
          <div class="memo-div">
            <div class="one">根据国家相关法律、法规要求</div>
            <div class="two">需要完成企业认证</div>
          </div>
          <div class="button-div" v-if="companyAuth.status==0">
            <button @click="$router.push('/authselect/2?redirect_url=/home/account/recharge')">前往认证</button>
          </div>
          <div class="status-div" v-else>
            <div class="status-title">企业认证：{{ companyAuth.company_name }}</div>
            <div class="status-memo">平台审核中，需3-5个工作日</div>
          </div>
        </div>
        <!-- 认证情况展示 end -->

        <!-- 仪表盘数据-->
        <div class="overview" v-show="companyAuth.status==9">
          <div class="card">
            <div class="title"> 累计充值</div>
            <div class="money"> ￥{{ wallet.acc_recharge_yuan || '0.00' }}</div>
            <div class="btn">
              <!-- <button class="recharge-btn">充值</button>-->
            </div>
          </div>
          <div class="card">
            <div class="title"> 可用余额</div>
            <div class="money"> ￥{{ wallet.available_all_yuan || '0.00' }}</div>
            <div class="btn">
              <button class="recharge-btn" @click="showRecharge">充值</button>
            </div>
          </div>
        </div>
        <!-- 仪表盘数据 end-->
      </div>

      <div class="bottom-content-box" v-show="indexBox">
        <div class="header">充值记录</div>

        <div class="table-div">
          <el-table :data="detailTableData" style="width: 100%"
                    center class="checkmantab" :border="false" key="two"
                    :header-cell-style="tableHeaderStyle"
                    :cell-style="tableCellStyle"> >
            <el-table-column prop="created_at" label="时间" width="160"/>
            <el-table-column label="收入/支出" width="80">
              <template slot-scope="{row}">
                {{ row.type == 'i' ? '收入' : '支出' }}
              </template>
            </el-table-column>
            <el-table-column prop="bill_type_name" label="资金类型"/>
            <el-table-column prop="fund_type_name" label="单据类型"/>
            <el-table-column label="金额">
              <template slot-scope="{row}">
                {{ row.type == 'i' ? '+' : '-' }}{{ row.amount_yuan }}
              </template>
            </el-table-column>
            <el-table-column prop="memo" label="备注"/>
          </el-table>
        </div>

        <div class="pagination-div">
          <el-pagination
              next-text="下一页"
              prev-text="上一页"
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :total="detailPagination.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="detailPagination.page_size"/>
        </div>
      </div>

      <div class="recharge-content-box" v-show="rechargeBox">
        <div class="money-div" v-show="recharge_money>0">
          需要充值： ￥{{ recharge_money }}元
        </div>
        <div class="bank-div">
          <Isvg icon-class="task-bank-pay" class-name="bank-icon"/>
          <div class="bank-name">
            <p>银行卡</p>
            <p>请使用 <span>{{ companyAuth.company_name }}</span> 对公账户</p>
          </div>
        </div>
        <div class="account-div" v-if="isEmpty(agentLocationData.bank_account)">
          <div class="header">托管账户信息</div>
          <div class="line-list"> 户名 ： {{ agentLocationData.plt_account.name }}</div>
          <div class="line-list"> 账户 ： {{ agentLocationData.plt_account.bankcard_no }}</div>
          <div class="footer"> 开户行 ： {{ agentLocationData.plt_account.bankcard_name }}</div>
        </div>
        <div class="account-div" v-else>
          <div class="header">托管账户信息</div>
          <div class="line-list"> 户名 ： {{ agentLocationData.bank_account.name }}</div>
          <div class="line-list"> 账户 ： {{ agentLocationData.bank_account.bankcard_no }}</div>
          <div class="footer"> 开户行 ： {{ agentLocationData.bank_account.bankcard_name }}</div>
        </div>
        <div class="checkbox-div">
          <!--          <el-radio v-model="agreementCheck" label="1">&nbsp;</el-radio>-->
          <!--          我已阅读并同意 <span>《资金托管协议》</span>。-->
          <!--          <el-alert style="margin-top: 10px" v-show="showCheckMsg" title="请先同意协议！" type="error" effect="dark"/>-->
        </div>
        <button class="transfer-btn" @click="showTransferBox">去转帐</button>
      </div>

      <div class="transfer-content-box" v-show="transferBox">
        <div class="header">请使用认证企业关联账户，转账付款</div>
        <div class="banner">
          <div class="card">
            <div>
              <Isvg icon-class="bank-logo1"/>
            </div>
            <div class="name">银行账号</div>
          </div>
          <div class="center-text">转账至</div>
          <div class="card">
            <div>
              <Isvg icon-class="bank-logo2"/>
            </div>
            <div class="name" v-if="isEmpty(agentLocationData.bank_account)">八爪灵工</div>
            <div class="name" v-else>资质地</div>
          </div>
        </div>
        <div class="memo">*八爪灵工为北京联众智赢科技有限公司旗下服务平台</div>
        <div class="account-div" v-if="isEmpty(agentLocationData.bank_account)">
          <div class="header">托管账户信息</div>
          <div class="line-list"> 户名 ： 北京联众智赢科技有限公司
            <button @click="copyText('北京联众智赢科技有限公司')">复制</button>
          </div>
          <div class="line-list"> 账户 ： 7510 0180 8097 78777
            <button @click="copyText('7510 0180 8097 78777')">复制</button>
          </div>
          <div class="footer"> 开户行 ： 中国光大银行股份有限公司北京新源支行
            <button @click="copyText('中国光大银行股份有限公司北京新源支行')">复制</button>
          </div>
        </div>
        <div class="account-div" v-else>
          <div class="header">托管账户信息</div>
          <div class="line-list"> 户名 ： {{ agentLocationData.bank_account.name }}
            <button @click="copyText(agentLocationData.bank_account.name)">复制</button>
          </div>
          <div class="line-list"> 账户 ： {{ agentLocationData.bank_account.bankcard_no }}
            <button @click="copyText(agentLocationData.bank_account.bankcard_no)">复制</button>
          </div>
          <div class="footer"> 开户行 ： {{ agentLocationData.bank_account.bankcard_name }}
            <button @click="copyText(agentLocationData.bank_account.bankcard_name)">复制</button>
          </div>
        </div>
        <div class="notify">
          <div class="title"><i class="el-icon-warning-outline"></i> <span>特别提醒</span></div>
          <div class="info">
            <p>1、需要您手动通过线下汇款、网银转账等方式将资金转入如上账户；</p>
            <p>2、转账成功后，平台将在3个工作日内完成资金核对，您可通过充值记录查看。</p>
          </div>
        </div>
        <button class="transfer-btn" @click="toInfo">已完成线下转账</button>

        <div class="msg">银行卡支付周期为1-3个工作日，请耐心等待，避免重复支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import {isEmpty} from "lodash-es";
import {getAgentInfo, getWallet, getWalletDetails, toRecharge} from "@/api/user";
import {getUserCompanyAuthList} from "@/api/auth";

export default {
  name: "new_index",
  data() {
    return {
      isEmpty,
      pageTitle: '企业账户充值',
      indexBox: true,
      rechargeBox: false,
      transferBox: false,
      loading: false,
      recharge_money: 0,
      task_id: 0,
      agentLocationData: {
        plt_account: {
          name: '北京联众智赢科技有限公司',
          bankcard_no: '7510 0180 8097 78777',
          bankcard_name: '中国光大银行股份有限公司北京新源支行'
        },
        bank_account: {}
      },
      companyAuth: {id: 0, status: 0},
      wallet: {},
      detailTableData: [],
      detailPagination: {
        page: 1,
        page_size: 10,
        total: 0,
        page_num: 1
      },
      detailSearchParams: {
        page: 1,
        page_size: 10,
        type: 'i',
        kw: ''
      },
      tableHeaderStyle: {
        'text-align': 'center',
        height: '32px',
        color: '#000',
        'font-size': '12px',
        background: '#F4FAFF'
      },
      tableCellStyle: {
        'text-align': 'center',
        height: '32px',
        color: '#474747',
        'font-size': '12px'
      },
      agreementCheck: false,
      showCheckMsg: false,

      rechargeSn: ''
    }
  },
  created() {
    this.loadData()//加载概览数据
    this.loadDetails(this.detailSearchParams)//加载充值记录数据
    this.loadCompanyAuthInfo()//加载企业认证信息
    if (this.$route.query.recharge_money) {
      this.recharge_money = Number(this.$route.query?.recharge_money)//接收页面传值
      this.task_id = this.$route.query?.task_id
      if(this.task_id>0){
        this.getAgent()
      }
    }
  },
  mounted() {
    // 如果有传值金额 直接赋值金额
    if (this.recharge_money > 0) {
      this.showRecharge()
    }
  },
  watch: {
    // agreementCheck(newval, oldval) {
    //   if (newval) {
    //     this.showCheckMsg = false
    //   }
    // }
  },
  methods: {
    async loadData() {
      this.loading = true
      let res = await getWallet({wallet_type: 2});// 默认取企业钱包
      if (res.statusCode == 200) {
        this.wallet = res.data
      }
      this.loading = false
    },
    async loadDetails(data) {
      let res = await getWalletDetails(data);
      if (res.statusCode == 200) {
        this.detailTableData = res.data.list
        this.detailPagination.page = Number(res.data.pages.page)
        this.detailPagination.page_size = Number(res.data.pages.page_size)
        this.detailPagination.total = Number(res.data.pages.total)
        this.detailPagination.page_num = Number(res.data.pages.page_num)
      }
    },
    async loadCompanyAuthInfo() {
      const res = await getUserCompanyAuthList()
      if (res.statusCode == 200) {
        if (res.data.length > 0) {
          this.companyAuth = res.data[0]
        }
      }
    },
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {
      this.detailSearchParams.page = val
      this.loadDetails(this.detailSearchParams)
    },
    showRecharge() {
      this.indexBox = false
      this.rechargeBox = true
    },
    hideRecharge() {
      this.indexBox = true
      this.rechargeBox = false
      this.transferBox = false
    },
    showTransferBox() {
      // if (!this.agreementCheck) {
      //   this.showCheckMsg = true
      //   return
      // }
      this.rechargeBox = false
      this.transferBox = true
      // 生成充值记录
      this.rechargeWallet()
    },
    async rechargeWallet() {
      let postData = {
        pay_channel: 1,
        payment_type: 3,
        amount: this.recharge_money > 0 ? this.recharge_money : 0,
        wallet_type: 2,
        task_id: this.task_id
      }
      if(!isEmpty(this.agentLocationData.bank_account)) {
        postData.pay_channel = 2
      }
      const res = await toRecharge(postData);
      if (res.statusCode == 200) {
        console.log('success')
        this.rechargeSn = res.data.sn
      }
    },
    async getAgent() {
      //TODO 需要同步阿拉钉的任务 托管的账户信息也要一致
      const res = await getAgentInfo({task_id: this.task_id})
      console.log(res,'xxxxxxxx')
      if (res.statusCode == 200) {
        if(!isEmpty(res.data)) {

          this.agentLocationData.bank_account = {
            name: res.data.agent_parent_name,
            bankcard_no: res.data.bankcard_no,
            bankcard_name: res.data.bankcard_name,
          }
        }else {
          this.agentLocationData.bank_account = {}
        }
      }
    },
    copyText(text) {
      try {
        let input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = text;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //移除input标签
        document.body.removeChild(input)
        this.$message.success("复制成功");
      } catch (e) {
        this.$message.error("复制失败");
      }
    },
    toInfo() {
      if (this.rechargeSn != '') {
        this.$router.push({path: '/home/account/recharge/detail', query: {sn: this.rechargeSn}})
      } else {
        this.$message.error('您还未进行充值！')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  background-color: #FFFFFF;
  min-height: 600px;

  // 头部
  .page-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0px 40px;
    height: 80px;
    border-bottom: 1px solid #EDEDED;

    .title {
      font-size: 20px;
      color: #000000;
    }

    .handle {
      font-size: 16px;

      .handle-button {
        color: #00ace6;
        font-size: 16px !important;
      }
    }
  }

  // 主体
  .page-content {
    padding: 0px 40px 40px 40px;

    .tip {
      background-color: #F4FAFF;
      height: 40px;
      width: 860px;
      font-size: 14px;
      color: #575757;
      display: flex;
      align-items: center;
      padding: 0px 16px;

      span {
        margin-left: 8px;
      }
    }

    .top-content-box {
      min-height: 200px;

      .auth-info {
        display: flex;
        text-align: center;
        flex-direction: column;
        margin-top: 60px;

        .icon-div {
          margin-bottom: 20px;

          .auth-icon {
            font-size: 80px;
          }
        }

        .memo-div {
          margin-bottom: 40px;
          font-size: 16px;
          line-height: 24px;
          color: #575757;

          .two {
            margin-top: 10px;
            font-weight: bold;
          }
        }

        .button-div {
          button {
            width: 200px;
            height: 40px;
            border-radius: 20px;
            background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
          }
        }

        .status-div {
          margin: 0 auto;
          width: 506px;
          height: 114px;
          border-radius: 8px;
          background: #F4FAFF;
          text-align: center;
          padding: 20px 0px;

          .status-title {
            font-size: 20px;
            line-height: 24px;
            color: #575757;
          }

          .status-memo {
            margin-top: 20px;
            font-size: 16px;
            line-height: 24px;
            color: #00B4FF;
          }
        }
      }

      .overview {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 200px;

        .card {
          width: 50%;
          text-align: center;

          .title {
            font-size: 20px;
            color: #575757;
            margin-bottom: 20px;
          }

          .money {
            font-size: 36px;
            color: #575757;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .btn {
            height: 32px;

            button {
              width: 140px;
              height: 32px;
              border-radius: 18px;
              background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
            }
          }

        }
      }

    }

    .bottom-content-box {
      margin-top: 20px;

      .header {
        display: flex;
        align-items: center;
        height: 80px;
        color: #000000;
      }

      .pagination-div {
        display: flex;
        justify-content: right;
        margin-top: 40px;
      }
    }

    .recharge-content-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .money-div {
        display: flex;
        align-items: center;
        color: #00ace6;
        font-size: 16px;
        font-weight: bold;
        height: 100px;
      }

      .bank-div {
        width: 540px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;

        .bank-icon {
          font-size: 60px;
        }

        .bank-name {
          margin-left: 40px;
          color: #575757;
          font-size: 14px;
          line-height: 24px;

          p:first-of-type {
            font-weight: bold;
            font-size: 16px;
          }

          span {
            color: #d4237a;
          }
        }
      }

      .account-div {
        width: 540px;
        height: 218px;
        border-radius: 8px;
        background: #F4FAFF;
        padding: 20px 40px;

        .header {
          height: 30px;
          font-size: 20px;
          color: #575757;
          font-weight: bold;
          border-bottom: 1px solid #EEEEEE;
        }

        .line-list {
          height: 50px;
          padding: 20px 16px;
          font-size: 16px;
          color: #575757;
          border-bottom: 1px solid #EEEEEE;
        }

        .footer {
          height: 50px;
          padding: 20px 0px;
          font-size: 16px;
          color: #575757;
        }
      }

      .checkbox-div {
        width: 540px;
        text-align: left;
        margin-top: 20px;
        color: #9C9C9C;
        font-size: 14px;
        padding-left: 40px;

        /deep/ .el-radio {
          margin-right: unset !important;
        }

        span {
          color: #00B4FF;
        }
      }

      .transfer-btn {
        margin-top: 60px;
        width: 200px;
        height: 40px;
        border-radius: 20px;
        background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
      }
    }

    .transfer-content-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        width: 540px;
        height: 84px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #575757;
        justify-content: center;
      }

      .banner {
        width: 540px;
        height: 134px;
        border-radius: 8px;
        background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 100px;
        color: #FFFFFF;

        .center-text {
          font-size: 20px;
        }

        .card {
          font-size: 16px;

          svg {
            font-size: 60px;
          }

          .name {
            margin-top: 10px;
            font-weight: bold;
          }
        }
      }

      .memo {
        font-size: 14px;
        color: #575757;
        margin-bottom: 20px;
      }

      .account-div {
        width: 540px;
        height: 218px;
        border-radius: 8px;
        background: #F4FAFF;
        padding: 20px 40px;
        margin-bottom: 20px;

        .header {
          height: 30px;
          font-size: 20px;
          color: #575757;
          font-weight: bold;
          border-bottom: 1px solid #EEEEEE;
          justify-content: unset !important;
        }

        .line-list {
          height: 50px;
          padding: 20px 0px 20px 16px;
          font-size: 16px;
          color: #575757;
          border-bottom: 1px solid #EEEEEE;

          button {
            float: right;
            width: 60px;
            height: 24px;
            border-radius: 12px;
            background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
          }
        }

        .footer {
          height: 50px;
          padding: 20px 0px;
          font-size: 16px;
          color: #575757;

          button {
            float: right;
            width: 60px;
            height: 24px;
            border-radius: 12px;
            background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
          }
        }
      }

      .notify {
        width: 540px;

        .title {
          display: flex;
          margin-bottom: 20px;

          .el-icon-warning-outline {
            color: #d4237a;
          }

          span {
            margin-left: 8px;
            color: #575757;
          }
        }

        .info {
          font-size: 14px;
          color: #575757;
          line-height: 24px;

          p {
            margin-bottom: 10px;
          }
        }
      }

      .transfer-btn {
        margin-top: 45px;
        width: 200px;
        height: 40px;
        border-radius: 20px;
        font-size: 18px;
        background: linear-gradient(270deg, #00B4FF 0%, #1DD0D5 100%);
      }

      .msg {
        margin-top: 20px;
        color: #d4237a;
        font-size: 14px;
      }
    }
  }
}
</style>
